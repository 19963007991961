import { Link, useLocation } from "react-router-dom";

export const _fleet: string = "/fleet";
export const _fleet_embed: string = "/fleet-embed";
export const _houses: string = "/houses";
export const _tarot: string = "/tarot";
export const _contact: string = "/contact";
export const _novel: string = "/novel";

export function MyNavBar() {
  var pathname = useLocation().pathname;

  var allinks: { to: string; text: string }[] = [
    { to: "/", text: "Tarot" },
//    { to: _tarot, text: "Tarot Cards" },
    { to: _novel, text: "Novel" },
    { to: _fleet, text: "Fleet" },
    { to: _houses, text: "Houses" },
    { to: _contact, text: "Contact" },
  ];

  return (
    <div
      style={{
        borderWidth: "1px",
        marginTop: "0.125em",
        textAlign: "center",
        zIndex: 10,
        marginBottom: ".5em",
      }}
    >
      {allinks.map((a, index) => {
        var isMatch = pathname.indexOf(a.to) >= 0;
        if (a.to === "/") {
          isMatch = pathname === a.to;
        }
        return (
          <Link
            className={`topLink ${isMatch && "toplinkActive"}`}
            key={index}
            to={a.to}
          >
            {a.text}
          </Link>
        );
      })}
    </div>
  );
}
