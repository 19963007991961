import { useEffect, useRef, useState } from "react";
import { MyWaterSphere } from "./WaterSphere";
import { OrbitControls, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { TextRoboto100 } from "../components/StyleComponents";

//import { Suspense } from "react";
//import { useProgress } from "@react-three/drei";

export interface Faction3D {
  preloadURLGLTF: string;
  Component3DFunction: () => JSX.Element;
  Non3d: () => JSX.Element;

  
  is3D:boolean;
}

export const houseOfHerodMeta3D: Faction3D = {
  is3D : true,
  Non3d: ()=> <div></div>,
  preloadURLGLTF: "/3dModels/Unity/Station_export_gltf/Station.gltf",
  Component3DFunction: () => {
    const [showWater, setShowWater] = useState(false);
    const [cameraPosition, setCameraPosition] = useState(false);
    let myRef: any = useRef();

    useThree(({ camera }) => {
      if (!cameraPosition) {
        setCameraPosition(true);
        camera.position.set(0, 0, 10);
      }
    });

    return (
      <>
        <ambientLight intensity={1} color={[1.0, 1.0, 1]} />
        <directionalLight
          position={[1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        <directionalLight
          position={[-1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        {showWater && (
          <MyWaterSphere
            myargs={[0.5, 7, 7]}
            pos={[0.7, -0.75, 0]}
          ></MyWaterSphere>
        )}

        {LoadGLTFMaySuspend(houseOfHerodMeta3D.preloadURLGLTF)}
        <OrbitControls
          ref={myRef}
          enableZoom={true}
          enablePan={false}
          autoRotate={false}
          autoRotateSpeed={0.25}
          maxPolarAngle={Math.PI / 2 + Math.PI * 0.22}
          minPolarAngle={Math.PI / 2 - Math.PI * 0.22}
          onChange={(a) => {
            if (!showWater && myRef.current.getDistance() < 2.75) {
              setShowWater(true);
            }
          }}
          maxDistance={211}
          minDistance={1.614}
        />
      </>
    );
  },
};
export const petraNovaMeta3D: Faction3D = {
  is3D : true,

  Non3d: ()=> <div></div>,
  preloadURLGLTF: "/3dModels/Unity/PetraNova_export_gltf/PetraNova.gltf",
  Component3DFunction: () => {
    //      <MyStars mySize={50} radius={15} depth={350} factor={0} />
    const [showWater, setShowWater] = useState(false);
    const [size, setSize] = useState(0);

    const myRef: any = useRef();

    useEffect(() => {
      setTimeout(() => {
        setShowWater(true);
        setSize(1);
      }, 500);
    });

    return (
      <>
        <ambientLight intensity={1} color={[1.0, 1.0, 1]} />
        <directionalLight
          position={[1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        <directionalLight
          position={[-1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />

        <group ref={myRef} scale={[size, size, size]} rotation={[0, 0, 0]}>
          {showWater && (
            <MyWaterSphere
              myargs={[1.8, 6, 6]}
              pos={[1.4, -0.5, 1.3]}
            ></MyWaterSphere>
          )}
          {LoadGLTFMaySuspend(petraNovaMeta3D.preloadURLGLTF)}
        </group>
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          maxPolarAngle={Math.PI / 2 + Math.PI * 0.12}
          minPolarAngle={Math.PI / 2 - Math.PI * 0.12}
        />
      </>
    );
  },
};

export const houseOfRealMeta3D: Faction3D = {
  is3D : false,

  Non3d: () => {
    return (
   
        <div className="selectDisable" style={   { height: "100vh", width: "100vw", backgroundColor: "black" }
      }>
        <img draggable="false" style={   {
          
          objectFit: "cover", 

          height: "100vh", width: "100vw"}}  src="/art/HouseOfReal.jpg"></img>


      </div>
    
    );
  },


  preloadURLGLTF: "/3dModels/Unity/HouseOfReal_export_gltf/HouseOfReal.gltf",
  Component3DFunction: () => {
    return (
      <>
        <ambientLight intensity={1} color={[1.0, 1.0, 1]} />
        <directionalLight
          position={[1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        <directionalLight
          position={[-1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
     />
        <TextRoboto100>coming soon</TextRoboto100>
      </>
    );
  },
};
export const andromedaHouse3D: Faction3D = {
  is3D : true,
  Non3d: ()=> <div></div>,

  preloadURLGLTF: "/3dModels/Unity/Andromeda_export_gltf/Andromeda.gltf",

  Component3DFunction: () => {
    //  var myCamera = ;
    //frameloop="demand"
    //  const prevImpl = THREE.Frustum.prototype.intersectsObject;
    THREE.Frustum.prototype.intersectsObject = function (
      object: THREE.Object3D
    ) {
      return true;
      /*
    const cullable = object as unknown as THREE.CullableObject;
    if (cullable.boundingSphere) {
      _sphere.copy(cullable.boundingSphere).applyMatrix4(object.matrixWorld);
      return this.intersectsSphere(_sphere);
    }
    return prevImpl.call(this, object);*/
    };

    return (
      <>
        {LoadGLTFMaySuspend(andromedaHouse3D.preloadURLGLTF)}
        <ambientLight intensity={0.5} color={[1.0, 1.0, 1]} />
        <directionalLight
          position={[1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        <directionalLight
          position={[-1, 0.2, 0]}
          intensity={1}
          color={[1.0, 1.0, 1]}
        />
        <OrbitControls
          makeDefault
          enableZoom={true}
          enablePan={false}
          autoRotate={false}
          autoRotateSpeed={0.25}
          maxPolarAngle={Math.PI / 2 + Math.PI * 0.29}
          minPolarAngle={Math.PI / 2 - Math.PI * 0.29}
          minDistance={1.9}
          maxDistance={10}
        />
      </>
    );
  },
};
export const khanDelinquentMeta3D: Faction3D = {
  is3D : true,

  preloadURLGLTF: "/3dModels/Unity/TowerOfBabel_export_gltf/Ship.gltf",

  Non3d: ()=> <div></div>,
  Component3DFunction: () => (
    <>
      <ambientLight intensity={1} color={[0.5, 0.5, 1]} />
      <directionalLight
        position={[1, 0.2, 0]}
        intensity={1}
        color={[1.0, 0.5, 0.5]}
      />
      <directionalLight
        position={[-1, 0.2, 0]}
        intensity={1}
        color={[0.5, 1.0, 1]}
      />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        minAzimuthAngle={-Math.PI / 4}
        maxAzimuthAngle={Math.PI / 4}
        autoRotate={false}
        autoRotateSpeed={0.25}
        maxPolarAngle={Math.PI / 2 + Math.PI * 0.12}
        minPolarAngle={Math.PI / 2 - Math.PI * 0.12}
      />
      {LoadGLTFMaySuspend(khanDelinquentMeta3D.preloadURLGLTF)}
    </>
  ),
};

/*
const Loading = () => {
  const { progress }: { progress: number } = useProgress();
  return <TextRoboto100>{Math.trunc(progress)}% loaded</TextRoboto100>;
};
*/

function LoadGLTFMaySuspend(filename: string) {
  const gltf = useGLTF(filename);
  return <primitive object={gltf.scene} />;
}

export function SuspenseWrapper(props: { children: React.ReactNode }) {
  // console.log("Suspense wrapper... not fully tested");
  // return <Suspense fallback={<Loading />}></Suspense>
  return <>{props.children}</>;
}
