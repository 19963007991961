import React, { CSSProperties, FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Text } from "@react-three/drei";
//import { AnimationClipCreator } from "three-stdlib";
export const Section: React.FC<{
  children: JSX.Element | string;
}> = ({ children }) => <h2>{children}</h2>;

export const SectionText: React.FC<{ children: ReactNode | string }> = ({
  children,
}) => <p className="SectionText">{children}</p>;

export const AnimatePulse: FunctionComponent<{ children: ReactNode }> = (
  props
) => {
  return (
    <div className="animate__animated animate__pulse">{props.children}</div>
  );
};

export const SubTitle: FunctionComponent<{
  children: ReactNode;
  className?: string;
  extra?: ReactNode;
  style?: React.CSSProperties;
}> = (props) => {
  return (
    <Centered>
      {props.extra}
      <h2
        className="pillBox"
        style={{
          ...props.style,
          margin: "0px",
          padding: "0.25em",
          borderRadius: "0.2em",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      >
        {props.children}
      </h2>
    </Centered>
  );
};

export const Centered: FunctionComponent<{
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}> = (props) => (
  <div style={props.style} className={"centerText " + props.className ?? ""}>
    {props.children}
  </div>
);

export const SubTitleLinkA: FunctionComponent<{
  children: ReactNode;
  to: string;
  className?: string;
  extra?: ReactNode;
}> = (props) => {
  return (
    <Link to={props.to}>
      <span style={{ display: "inline-block" }}>
        {props.extra}
        {props.children}
      </span>
    </Link>
  );
};

const fontFile = "/roboto-latin-100-normal.woff";

export const TextRoboto100: FunctionComponent<{ children: ReactNode }> = (
  props
) => {
  return (
    <Text scale={10} font={fontFile}>
      {props.children}
    </Text>
  );
};
