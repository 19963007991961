import React, { FunctionComponent } from "react";
import { ThankYou } from "../SourceStatements";
import { SignupForm } from "./Signup";
const TableItem: React.FC<{
  a: JSX.Element | string;
  b: JSX.Element | string;
  c: JSX.Element | string;
  i:  string;
}> = ({ a, b, c ,i}) => {
  return (
    <div style={{ clear: "both" }}>
      <h3 style={{ textAlign: "left" }}>{b}</h3>
      <span
        style={{ float: "left", paddingRight: "1em", paddingBottom: "1em" }}
      >
        {a}
      </span>
      <p style={{ textAlign: "justify" }}>{c}</p>
      <br/>
<span  style={{  textAlign: "left" , marginLeft:0, display:"block" }}>Follow on Instagram : <a href={"https://www.instagram.com/"+i}>@{i}</a>
</span>

    </div>
  );
};

const Artists = () => {
  return (
    <>
      <InnerPillbox>
        <h1 style={{ textAlign: "center" }}>Team</h1>
        <TableItem
          a={
            <img
              alt="Creator Dr. Clement Shimizu"
              style={{
                width: "128px",
                height: "128px",
              }}
              src={`credits/Dr-Clement-Shimizu.png`}
            ></img>
          }
          b={"Story/Art Direction/3D Website"}
          c={
            "Dr. Clement Shimizu is an academic, entrepreneur, & artist. He received a B.S. in Mathematics & PhD. in Computer Sci, co-owns Elumenati.com (VR projection displays and software), & has received numerous awards for work in engineering & arts. Highlights include NASA Major Space Act Award, top prize at Pirate Jam 2019 Reality TV show."
          }
          i="drclementshimizu"
        ></TableItem>

      <br/>
        <TableItem
          a={
            <img
              alt="Illustration by Yulika Murkami"
              style={{
                width: "128px",
                height: "128px",
              }}
              src={`credits/Yulika-Murkami.png`}
            ></img>
          }
          b={"Illustration"}
          c={
            "Yulika Murakami is a Japanese Brazilian artist who recently graduated from art school.  Her final project involved creating and wearing alien-like prosthetics in everyday life situations.  Yulika interned as a 2D animator and technical artist, and has worked on many projects with Dr. Shimizu."
          }

          i="n.yulika"
        ></TableItem>
      </InnerPillbox>
    </>
  );
};

const InnerPillbox = (props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className="pillBoxResponsive title myBackgroundAndBorderColorAndTextColor"
      style={{
        ...props.style,
        maxWidth: "90vw",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        borderColor: "white",
        zIndex: -1,
      }}
    >
      {props.children}
    </div>
  );
};

export const InnerPillboxReactiveSignup = (props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className="pillBoxResponsive title myBackgroundAndBorderColorAndTextColor"
      style={{
        ...props.style,
        maxWidth: "90vw",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        borderColor: "white",
        zIndex: -1,
        position: "unset",
      }}
    >
      {props.children}
    </div>
  );
};

export const Team: FunctionComponent = () => {
  return ( 

    <div className="centerText">
    <div
      className="pillBoxResponsive myBackgroundAndBorderColorAndTextColor limitWidth"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: ".5em",
        marginTop: ".5em",
        backgroundColor:"white",
width:"540px", 

      }}
    >


        <SignupForm />

      <Artists />

      <ThankYou />
      <br/>
    </div></div>
  );
};
