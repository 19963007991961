import React from "react";
import { MeshDistortMaterial } from "@react-three/drei";

// = [1.8, 6, 6]
// = [1.4, -0.5, 1.3]
export function MyWaterSphere(params: { myargs: number[]; pos: number[] }) {
  let x = (
    <MeshDistortMaterial
      metalness={0.0}
      roughness={0.0}
      thickness={0.5}
      distort={0.23}
      ior={21.9}
      reflectivity={0.5}
      transmission={1}
      speed={1.55}
    />
  );

  return (
    <mesh position={[params.pos[0], params.pos[1], params.pos[2]]}>
      <sphereBufferGeometry
        args={[params.myargs[0], params.myargs[1], params.myargs[2]]}
      />
      {x}
    </mesh>
  );

  //
}
