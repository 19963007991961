import { Canvas } from "@react-three/fiber";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react"; //, { useRef, Suspense, useState }
import { OrbitControls, Stars } from "@react-three/drei";
import { SuspenseWrapper } from "./HouseOfHerod3D";
import { SubTitleLinkA } from "../components/StyleComponents";
import { useParams } from "react-router-dom";
import { _fleet, _houses, _fleet_embed } from "../components/Navigation";

import { GetFactionByLinkSuffix, Faction, allHouses } from "./Houses";

export function Debug3D() {
  //  const gltf = useGLTF(houseOfHerodMeta.preloadURLGLTF);
  //   <primitive object={gltf.scene} />;
  return (
    <>
      <div>Build 64</div>
      <Canvas style={{ height: "80vh", backgroundColor: "#220011" }}>
        <OrbitControls />
        <Stars></Stars>
      </Canvas>
    </>
  );
}

export const LocationsEmbed: FunctionComponent = () => {
  // WE ARE NOT USING THE ROUTER HERE
  var faction: Faction = GetFactionByLinkSuffix(window.location.pathname);

  return (
    <Fragment>
    <Canvas
      style={
        faction.scene3D.is3D?  
        
        { height: "100vh", width: "100vw", backgroundColor: "black" }:
      {display:"none"}
      }
      linear
      className=  "threeJSShip"
      frameloop="demand"
      camera={{ near: 0.11 }}
    >
      <SuspenseWrapper>
      <faction.scene3D.Component3DFunction />
       
      </SuspenseWrapper>
    </Canvas>
     <faction.scene3D.Non3d/> 
    </Fragment>
  );
};

function Nav(params: { style?: React.CSSProperties; prefix: string }) {
  var { name } = useParams();
  return (
    <div
      style={{
        zIndex: "1",
        top: "0",
        marginLeft: ".25em",
        marginRight: "auto",
        marginTop: "0.75em",
        ...params.style,
      }}
    >
      {allHouses.map((info, index) => {
        var isMatch =
          name === info.linkSuffix || (name === undefined && index === 0);
        return (
          <SubTitleLinkA key={index} to={params.prefix + "/" + info.linkSuffix}>
            <img
              src={info.svgLogo}
              className="shipLogoSmall"
              alt="logo"
              style={{
                borderWidth: "4px",
                borderColor: isMatch ? "black" : "black",
                borderStyle: isMatch ? "dotted" : "double",
                position: "relative",
                top: isMatch ? "-8px" : "0px",
                height: isMatch ? "48px" : "32px",
                width: isMatch ? "48px" : "32px",
                marginBottom: isMatch ? "-8px" : "0px",
              }}
            />
          </SubTitleLinkA>
        );
      })}
    </div>
  );
}

function MoreDetails(props: { faction: Faction }) {
  function MoreDetailHelper(props: { a: string; b: string }) {
      if(props.b ===""){
        return <></>
      }

    return ( 
      <tr>
        <td
          style={{
            textAlign: "right",
            verticalAlign: "top",
          }}
        >
          {props.a}
        </td>
        <td style={{ verticalAlign: "top" }}>:</td>
        <td
          style={{
            textAlign: "left",
            verticalAlign: "top",
          }}
        >
          {props.b}
        </td>
      </tr>
    );
  }
  return (
    <table
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "200px" }}
    >
      <tbody>
        <MoreDetailHelper a="House" b={props.faction.house} />
        <MoreDetailHelper a="Borough" b={props.faction.borough} />
        <MoreDetailHelper a="Suit" b={props.faction.suit} />
        <MoreDetailHelper a="Forte" b={props.faction.danceStyle} />
        <MoreDetailHelper a="Divinity" b={props.faction.religion} />
        <MoreDetailHelper a="Style" b={props.faction.fashionStyle} />
        <MoreDetailHelper a="Element" b={props.faction.element} />
      </tbody>
    </table>
  );
}

export function Fleet() {
  var { name } = useParams();

  const [isGlobalInstructions, setIsGlobalInstructions] = useState(false);
  useEffect(() => {
    var interval = setInterval(() => {
      setIsGlobalInstructions(!isGlobalInstructions);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  var faction: Faction = GetFactionByLinkSuffix(name);
  return (
    <div className="centerText">
    <div
      className="pillBoxResponsive myBackgroundAndBorderColorAndTextColor limitWidth"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: ".5em",
        marginTop: ".5em",
        backgroundColor:"white",
width:"540px", 
textAlign:"left"

      }}
    >


<h1 style={{textAlign:"center", marginTop:0 }}>Fleet</h1>

      <div
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
          <Nav
            style={{
              position: "absolute",
            }}
            prefix={_fleet}
          />
         
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            bottom: "0",
            marginLeft: ".5em",
            marginRight: "auto",
            marginBottom: "0.75em",
            color: "white",
            fontWeight: "500",
            textShadow:
              "#000 -1px -1px, #000 1px 1px, #000 1px -1px, #000 -1px 1px",
          }}
        >
          {isGlobalInstructions
            ? "Click the flags above to check out the different Houses"
            : faction.instructions}
          {/*  {faction.myName} - {faction.borough} - {faction.suit}*/}
        </div>
        <div style={{ paddingBottom: 0 }}>
          <iframe
            className="myBackgroundAndBorderColorAndTextColor"
            src={`${_fleet_embed}/${faction.linkSuffix}`}
            title={faction.house + " 3d Space Ship preview"}
            style={{
              width: "100%",
              height: "50vh",
              borderStyle: "solid",
              borderRadius: "1em",
            }}
          />
        </div>
      </div>
      <div
        className="pillBoxResponsive title "
        style={{
          maxWidth: "90vw",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: ".5em",
          marginTop: ".5em",
          backgroundColor:"white",
          textAlign:"left",
          borderBottom:"none"
        }}
      >

        <div>{faction.descriptionPre}</div>
        <div style={{ textAlign: "justify" }}>{faction.description}</div>
      </div>


    </div>
    <br/>
    </div>
  );
}

export function Houses() {
  var { name } = useParams();

  const [isGlobalInstructions, setIsGlobalInstructions] = useState(false);
  useEffect(() => {
    var interval = setInterval(() => {
      setIsGlobalInstructions(!isGlobalInstructions);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  var faction: Faction = GetFactionByLinkSuffix(name);
  return (
    <div className="centerText">
    <div
      className="pillBoxResponsive myBackgroundAndBorderColorAndTextColor limitWidth"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: ".5em",
        marginTop: ".5em",
        backgroundColor:"white",
        width:"540px", 
        textAlign:"center"
      }}
    >
          <h1 style={{ marginBottom: ".5em" , marginTop:"0" }}>Select a House</h1>
          {<Nav prefix={_houses} style={{ marginTop: 0 ,marginBottom: ".5em" }} />}
        <img
          src={faction.art.url}
          alt={faction.art.alt}
          width={faction.art.width}
          height={faction.art.height}
          style={{
            display:"block",
            marginLeft:"auto",
            marginRight:"auto",
            maxWidth: "100%",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: ".5em",
            borderColor: "lightgray",
          }}
        />
      
      <MoreDetails faction={faction} />
        <h3>{faction.art.alt}</h3>
    </div></div>
  );
}
