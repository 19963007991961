//import React from "react";

import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { LocationsEmbed } from "./cgComponents/HouseOfHerod";

import { currentEnv } from "./env";
if (!currentEnv.embeded)
  createRoot(document.getElementById("root")!).render(<App />);
else {
  createRoot(document.getElementById("root")!).render(<LocationsEmbed />);
  document.body.style.overflowY = "hidden";
}

if (!currentEnv.devFriendly) {
  document.body.style.userSelect = "none";
}

//createRoot(document.getElementById('threejs')!).render(
//  <Locations3JS />
//);

//createRoot(document.getElementById('root')!).render(  <React.StrictMode>   <App />  </React.StrictMode>,);
/*

    "@babel/core": "7.17.8",
    "@babel/preset-env": "7.16.11",
    "@babel/preset-react": "7.16.7",
    "@babel/preset-typescript": "^7.16.7",
    "@changesets/changelog-git": "^0.1.11",
    "@changesets/cli": "^2.22.0",
    "@preconstruct/cli": "^2.1.5",
    "@testing-library/react": "^13.0.0-alpha.5",
    "@types/jest": "^27.4.1",
    "@types/react": "18.0.8",
    "@types/react-dom": "18.0.3",
    "@types/react-test-renderer": "^17.0.1",
    "@types/scheduler": "^0.16.2",
    "expo-asset": "^8.4.6",
    "expo-gl": "^11.1.2",
    "husky": "^7.0.4",
    "jest": "^27.5.1",
    "jest-cli": "^27.5.1",
    "lint-staged": "^12.3.7",
    "prettier": "^2.6.1",
    "pretty-quick": "^3.1.3",
    "react-native": "0.67.4",
    "react-test-renderer": "^18.0.0",
    "regenerator-runtime": "^0.13.9",
    "three-stdlib": "^2.8.11",
    "ts-jest": "^27.1.4",
    "@fontsource/roboto": "^4.5.5",
    "@fortawesome/fontawesome-svg-core": "^6.1.1",
    "@fortawesome/free-brands-svg-icons": "^6.1.1",
    "@fortawesome/free-solid-svg-icons": "^6.1.1",
    "@fortawesome/react-fontawesome": "^0.1.18",
    */
