var BUILD_ENVstr: string = process.env.REACT_APP_BUILD_ENV || "local";

interface BuildEnvInterface {
  shortname: string;
  url: string;
  isActive: boolean;
  REACT_APP_BUILD_ENV: string;
  embeded: boolean;
  isLocal: boolean;
  devFriendly: boolean;
}

var embeded =
  window.location.pathname.indexOf("embed") >= 0 ||
  window.location.pathname.indexOf("/embed/") >= 0;

var temp: BuildEnvInterface | null;
switch (BUILD_ENVstr) {
  case "live":
    temp = {
      shortname: "live",
      url: "https://t5ny.com",
      isActive: false,
      REACT_APP_BUILD_ENV: "live",
      embeded: embeded,
      isLocal: false,
      devFriendly: false,
    };
    break;
  case "dev":
    temp = {
      shortname: "dev",
      url: "https://dev.t5ny.com",
      isActive: false,
      REACT_APP_BUILD_ENV: "dev",
      embeded: embeded,
      isLocal: false,
      devFriendly: true,
    };
    break;
  case "prod":
    temp = {
      shortname: "dev",
      url: "https://prod.t5ny.com",
      isActive: false,
      REACT_APP_BUILD_ENV: "prod",
      embeded: embeded,
      isLocal: false,
      devFriendly: false,
    };
    break;
  case "local":
    temp = {
      shortname: "local",
      url: "http://localhost:3000",
      isActive: false,
      REACT_APP_BUILD_ENV: "",
      embeded: embeded,
      isLocal: false,
      devFriendly: true,
    };
    break;
  default:
    console.log("Unknown env " + BUILD_ENVstr);
    temp = {
      shortname: "local",
      url: "http://localhost:3000",
      isActive: false,
      REACT_APP_BUILD_ENV: "",
      embeded: embeded,
      isLocal: true,
      devFriendly: true,
    };
    break;
}

export const currentEnv: BuildEnvInterface = temp;
