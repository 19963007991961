import React, { ReactNode } from "react";
import { SectionText } from "./components/StyleComponents";

const SectionTy: React.FC<{ children: ReactNode | string }> = ({
  children,
}) => (
  <div
    className="pillBoxResponsive title myBackgroundAndBorderColorAndTextColor"
    style={{
      maxWidth: "90vw",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      borderColor: "white",
      textAlign: "left",
    }}
  >
    {children}
  </div>
);
export const ThankYou = () => {

  return <></>;

  return (
    <div>
      <SectionTy>
        <h3>Licence Notes</h3>
        <SectionText>
          T5NY and HouseOfHerod utilize of many pieces of open source technolgy.
          Use of the technolgy does not represent endorsement one way or
          another.
          <br />
          The licences can be found here:
        </SectionText>
        <ul>
          {myLibs.libs.map((lib, index) => (
            <li key={index}>
              <DrawLibraryLine dep={lib}></DrawLibraryLine>
            </li>
          ))}
        </ul>
      </SectionTy>
      <SectionTy>
        <h3>Citations</h3>
        The story draws inspiration from many sources. Please check out the
        footnotes found in the full story for detailed citations. The story is
        illustrated with art inspired by early tarot decks including RWS by
        Arthur Edward Waite and Pamala Coleman Smith 1909, Sola Busca tarot from
        1490, and Jean Noblet version of the tarot Marseilles from 1650.
      </SectionTy>
    </div>
  );
};

const DrawLibraryLine = (props: { dep: LibraryDep }) => {
  return (
    <a
      className="myBackgroundAndBorderColorAndTextColor"
      href={"https://www.npmjs.com/package/" + props.dep.name_version[0]}
      style={{
        textDecoration: "none",
      }}
    >
      {props.dep.name_version[0]}
    </a>
  );
};
interface LibraryDep {
  name_version: [string, string];
}
interface LibraryDeps {
  libs: LibraryDep[];
}
const myLibs: LibraryDeps = {
  libs: [
    { name_version: ["@preconstruct/cli", "^2.1.5"] },
    { name_version: ["animate.css", "^4.1.1"] },
    { name_version: ["husky", "^8.0.1"] },
    { name_version: ["prettier", "^2.6.2"] },
    { name_version: ["sass", "^1.51.0"] },
    { name_version: ["typescript", "4.4.2"] },
    { name_version: ["@react-three/drei", "^9.11.0"] },
    { name_version: ["@react-three/fiber", "^7.0.29"] },
    { name_version: ["@testing-library/react", "^13.2.0"] },
    { name_version: ["@types/jest", "^27.5.1"] },
    { name_version: ["@types/react-dom", "^18.0.4"] },
    { name_version: ["@types/three", "^0.140.0"] },
    { name_version: ["react", "^18.1.0"] },
    { name_version: ["react-dom", "^18.1.0"] },
    { name_version: ["react-router-dom", "^6.3.0"] },
    { name_version: ["react-scripts", "^5.0.1"] },
    { name_version: ["three", "^0.140.2"] },
  ],
};
