import React, { FunctionComponent } from "react";

import "./App.css";
import { About, Novel } from "./components/About";
import { Team } from "./components/Team";
//import { allIcons } from "./components/Snipets";
import { Fleet, Houses } from "./cgComponents/HouseOfHerod";
import { DrawRSSFeed } from "./components/Rss";
import { Centered } from "./components/StyleComponents";
import { GlobalStateProvider } from "./VolumeContext";
import { BrowserRouter, Route, Routes } from "react-router-dom"; 
import {
  MyNavBar,
  _contact,
  _fleet,
  _houses,
  _novel,
  _tarot,
} from "./components/Navigation";

const Title: FunctionComponent<{}> = () => {
  return (
    <Centered
      style={{
        overflow: "hidden",
      }}
    >
      <div
        className="pillBoxResponsive title topTitle myBackgroundAndBorderColorAndTextColor "
        style={{
          textAlign: "center",
          overflow: "visible",
          borderColor: "lightgray",
          borderWidth: "1px",
          position: "relative",
        }}
      >
        <h1
          style={{
            margin: "0",
          }}
        >
          The Five Houses<br/>of Nova&nbsp;York
        </h1>
        <h2
          style={{
            margin: "0",
          }}
        >
          A 78-card Tarot Masterpiece &amp; Sci-Fi novel
        </h2>
        <div
          className="leaf  flipY"
          style={{
            display: "block",
            zIndex: -2,
            position: "absolute",
            right: "-120px",
            top: ".25em",
          }}
        />
        <div
          className="leaf flipX"
          style={{
            display: "block",
            zIndex: -2,
            position: "absolute",
            left: "-120px",
            top: ".25em",
          }}
        />
      </div>
    </Centered>
  );
};

function MyFooter(props: { style: React.CSSProperties }) {
  return (
    <div
      style={{
        ...props.style,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginTop: "0.5em",
      }}
    >
      <span className="leaf  flipX leftLeafRelative"></span>
      <a
        className="myBackgroundAndBorderColorAndTextColor footerCopyright"
        style={{
          marginTop: ".5em",
          padding: ".5em",
          textDecoration: "none",
        }}
        href="http://www.clementshimizu.com"
        target="_blank"
        rel="noreferrer"
      >
        &copy;DrClementShimizu
      </a>
      <span className="leaf flipY rightLeafRelative"></span>
    </div>
  );
}

function Branches() {
  return (
    <div>
      <a style={{}} href="https://dev.d1c3ra9hp566nv.amplifyapp.com/">
        Dev
      </a>
      <a style={{}} href="https://prod.d1c3ra9hp566nv.amplifyapp.com/">
        Prod
      </a>
      <a style={{}} href="http://localhost:3000/">
        Local
      </a>
    </div>
  );
}

export class App extends React.Component {
  render() {
    return (
      <GlobalStateProvider>
        <Title />
        <BrowserRouter>
          <MyNavBar />
          <Routes>
            {/* unofficial */}
            <Route path="/branches" element={<Branches />} />
            <Route path="/about" element={<About />} />
            {/* official */}
            <Route path="/" element={<About />} />
            <Route path={_contact} element={<Team />} />
            <Route path={_novel} element={<Novel />} />
            <Route path={_fleet + "/:name"} element={<Fleet />} />
            <Route path={_fleet + "/*"} element={<Fleet />} />
            <Route path={_fleet} element={<Fleet />} />
            <Route path={_houses + "/:name"} element={<Houses />} />
            <Route path={_houses + "/*"} element={<Houses />} />
            <Route path={_houses} element={<Houses />} /> 
          </Routes>
          <MyFooter style={{ marginBottom: "1em" }} />
{/*          <DrawRSSFeed />*/}
        </BrowserRouter>
      </GlobalStateProvider>
    );
  }
} 

/*
import { MusicPlayer, Volume } from "./components/MusicPlayer";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";

*/

//import {Centered} from './components/StyleComponents'
/*
const Screenshots: FunctionComponent= () =>{
  return (
    <div style={{
      maxWidth: "1000px",
      marginLeft: "auto",
      marginRight: "auto"
    }}>

            <MyNavBar />
      {allIcons.map((CC, b) => (
        <Fragment key={b}>{CC}</Fragment>
      ))}

            <MyFooter />
    </div>
  );
}
*/
/*      <Link style={style2} to="/branches">
        Branches
      </Link>*/

/*

        </ButtonGroup>
      </Nav>
    </Navbar>
    </Centered>
    <Navbar style={{
    marginTop:"0px",
    paddingTop:"0px",
    marginBottom:".25em",
    }}>
      <Nav className="  " style={{ marginLeft: "auto", marginRight: "auto",marginTop:"0px",
    paddingTop:"0px"}}>
        <ButtonGroup>
        */

