import { ReactNode } from "react"; //, { useRef, Suspense, useState }
import * as Houses3D from "./HouseOfHerod3D";
import { SectionText } from "../components/StyleComponents";
import RubElHizab from "../flags/rub-el-hizb-islamic-symbol.svg";
import HouseOfHerodLogo from "../flags/house-of-herod.svg";
import HouseOfRealLogo from "../flags/houseofreal.svg";
import KhanLogo from "../flags/KhanLogo.svg";
import AndromedaHouseLogo from "../flags/AndromedaHouseLogo.svg";

export interface Faction {
  borough: string;
  house: string;
  suit: string;
  description: ReactNode;
  descriptionPre?: ReactNode;
  linkSuffix: string;
  svgLogo: any;
  danceStyle: string;
  religion: string;
  fashionStyle: string;
  element: string;
  art: ImageRef;
  instructions: string;
  signs: string[];
  scene3D: Houses3D.Faction3D;
}

interface ImageRef {
  url: string;
  alt: string;
  width: number;
  height: number;
}

export const houseOfHerodMeta: Faction = {
  borough: "Manhattan",
  danceStyle: "Hands",
  religion: "Secular Fraternity",
  house: "House of Herod",
  suit: "Major Arcana",
  linkSuffix: "house-of-herod",
  fashionStyle: "High Fashion",
  element: "",
  svgLogo: HouseOfHerodLogo,
  scene3D: Houses3D.houseOfHerodMeta3D,
  descriptionPre: null,
  signs: [],
  art: {
    url: "/art/The_Emperor_Tarot.jpg",
    alt: "King Herod in a rare mood",
    height: 559,
    width: 337,
  },
  description: (
    <>
      <SectionText>
        Manhattan maintains a space station titled House of Herod, run by an
        erratic party animal named King Herod. This gigantic space station is a
        gathering point welcoming all cultures while presenting itself as the
        leading innovator of style, fashion, and arts.
<br/>
        Zoom into the Inner Garden temple to find a zero gravity playground for King Herod and
        his court.
      </SectionText>
    </>
  ),
  instructions:
    "Zoom in and out to explore House of Herod's space station and Inner Garden.",
};

export const petraNovaMeta: Faction = {
  borough: "Bronx",
  house: "Petra Nova",
  danceStyle: "Rythmic Martial Arts", // (Capoeira & Professional Wrestling)
  religion: "Muslim",
  fashionStyle: "Exotic",
  suit: "Swords",
  linkSuffix: "petra-nova",
  element: "Air",
  signs: ["Gemini", "Libra", "Aquarius"],
  svgLogo: RubElHizab,
  scene3D: Houses3D.petraNovaMeta3D,
  art: {
    url: "/art/T5NY_4_of_Swords.jpg",
    alt: "The Petra Novans enjoyed professional wrestling and Capoiera over dance.",
    height: 559,
    width: 337,
  },
  description: (
    <>
      <SectionText>
        Two spacecraft, controlled by an Arab community headquartered in the
        Bronx, were sent to capture and merge a near-earth copper asteroid with
        a comet to create a new half world and half ship for space-faring
        Islamic people to enjoy. The artificial world is called Petra Nova. The
        Petra Novans do not compete in the dance parties common among the other
        Houses. Instead, they have a rhythmic martial arts troupe that developed
        a unique zero-gravity combination of Brazilian Capoeira and professional
        wrestling.
      </SectionText>
    </>
  ),
  instructions: "Drag to view the space station from all sides",
};

export const houseOfRealMeta: Faction = {
  borough: "Brooklyn",
  house: "House of Real",
  danceStyle: "Vogue",
  religion: "Secular Atheist",
  fashionStyle: "Club Kid",
  suit: "Wands",
  element: "Fire",
  linkSuffix: "house-of-real",
  svgLogo: HouseOfRealLogo,
  scene3D: Houses3D.houseOfRealMeta3D,
  signs: ["Aries", "Leo", "Sagittarius"],
  art: {
    url: "/art/Party_Animals_in_Space.jpg",
    alt: "House of Real dancers training for zero gavity dance events by jumping down the stairwell.",
    height: 559,
    width: 337,
  },
  description: (
    <>
      <SectionText>
        House of real is a space ship filled with unqualified party animals bored out of thier minds and itching for change.
      </SectionText>
    </>
  ),
  instructions: "Concept art for House of Real.",
};
export const andromedaHouse: Faction = {
  borough: "Staten Island",
  house: "Andromeda House",
  suit: "Coins",
  danceStyle: "Disco",
  fashionStyle: "70s Retro Glam",
  religion: "Postmodern Opus Dei Catholicism",
  linkSuffix: "andromeda-house",
  element: "Earth",
  signs: ["Taurus", "Virgo", "Capricorn"],
  svgLogo: AndromedaHouseLogo,
  scene3D: Houses3D.andromedaHouse3D,
  art: {
    url: "/art/Amputation_To_Save_on_Gas.jpg",
    alt: "The first missionaries had limbs surgically removed to save on weight for space travel.",
    height: 559,
    width: 337,
  },
  description: (
    <>
      <SectionText>
        The Catholic Church's archdiocese located on Staten Island ran a
        controversial space program, code-named The Andromeda Mission. The
        Cardinal recruited die-hard Opus Dei missionaries to go on one-way
        missions to space with the purpose of radio broadcasting prayers from
        space.
      </SectionText>
      <SectionText>
        Because of budget cuts, the first missionary needed to be quadruply
        amputated to save on launch weight. The mission was a disaster as the
        missionary became confused during an extended perpetual adoration and
        starved to death. The second mission was even more monstrous. On the
        second and many subsequent missions, the Opus Dei would come to the
        operating room in pairs matched by blood type. The surgeon would select
        one volunteer for limb removal. The other volunteer was decapitated, and
        the head grafted onto the torso of the other.
      </SectionText>
      <SectionText>
        The resulting stump with two heads could now take turns in the practice
        of perpetual adoration. While one slept and ate, the other would go on
        with the rosary. When the head needed to sleep the other head would take
        over and continue on the rosary where the first left off. This back and
        forth of one body with two heads required only a little more nutrients
        than the single-headed stump, but it had many advantages. Each head
        would keep the other from going off the rails. This second mission was a
        success. The space capsule holding only the two-headed stump, the food
        to keep them alive, and a small radio entered Martian orbit. They
        recited the rosary in perpetual adoration over the radio for months.
      </SectionText>
      <SectionText>
        A flood of donations came from Catholics sympathetic to extraterrestrial
        evangelism. The team sent many more missions up and eventually was able
        to establish a permanent church and colony called Andromeda House.
      </SectionText>
    </>
  ),

  instructions:
    "Zoom in and out to inspect Andromeda House's first mission to Mars",
};
export const khanDelinquent: Faction = {
  borough: "Queens",
  house: "Khan Deliquent",
  danceStyle: "Power Moves",
  religion: "Pop Kabbalism",
  suit: "Cups",
  fashionStyle: "Avant-garde",
  linkSuffix: "khan-deliquent",
  element: "Water",
  signs: ["Cancer", "Scorpio", "Pisces"],
  svgLogo: KhanLogo,
  scene3D: Houses3D.khanDelinquentMeta3D,
  art: {
    url: "/art/SciFi_Themed_Tarot.jpg",
    alt: "Without a ship, House of Khan rebranded themselves as Khan Delinquent.",
    height: 559,
    width: 337,
  },
  description: (
    <>
      <SectionText>
        Queens’ underground orthodox synagogue, House of Khan, was torn between
        new-age enlightenment and ancient mysticism. However, much of the
        population was lost when their space ark exploded. The remaining
        population survived by floating around in cargo containers and escape
        pods. Their strong sense of community and commitment to ancient ways
        tied them together. Without a ship, House of Khan rebranded themselves
        as Khan Delinquent. With a new name came a new style repurposing old
        conservative garments harvested from the lost in new and exciting ways.
      </SectionText>
    </>
  ),
  instructions:
    "Khan Deliquent's disaster that left most of the population without a home",
};

export const allHouses: Faction[] = [
  houseOfHerodMeta,
  petraNovaMeta,
  khanDelinquent,
  houseOfRealMeta,
  andromedaHouse,
];
export function GetFactionByLinkSuffix(suffix?: string): Faction {
  suffix = suffix?.toLowerCase();

  if (suffix?.includes(petraNovaMeta.linkSuffix)) {
    return petraNovaMeta;
  } else if (suffix?.includes(andromedaHouse.linkSuffix)) {
    return andromedaHouse;
  } else if (suffix?.includes(khanDelinquent.linkSuffix)) {
    return khanDelinquent;
  } else if (suffix?.includes(houseOfRealMeta.linkSuffix)) {
    return houseOfRealMeta;
  } else if (suffix?.includes(houseOfHerodMeta.linkSuffix)) {
    return houseOfHerodMeta;
  } else {
    return houseOfHerodMeta;
  }
}
