import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";


export interface GlobalStateInterface {

  muted: boolean,
  numberOfSourcesInForground: number,
}

var defaultState: GlobalStateInterface = { muted: true, numberOfSourcesInForground: 0 };

const GlobalStateContext = createContext({
  state: defaultState as Partial<GlobalStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

interface GlobalStateProviderInterface {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}

const GlobalStateProvider = ({
  children,
  value = defaultState as GlobalStateInterface,
}: GlobalStateProviderInterface) => {
  const [state, setState] = useState(value);
  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

const Debug = () => {
  const { state } = useGlobalState();
  return <pre>{JSON.stringify(state || {}, null, "\t")}</pre>;
};

export { GlobalStateProvider, useGlobalState, Debug };