import MailchimpSubscribe from "react-mailchimp-subscribe"
import {Centered} from './StyleComponents'
import {useState} from "react";

const url = "https://T5ny.us10.list-manage.com/subscribe/post?u=ac64a41cea256e854d4c23c4d&amp;id=95eb8e4f6f";


// a basic form
const CustomForm = ({ status, message, onValidated }) => {


  const [error2,SetError2]=useState("ok");  
  const [message2,SetMessage2]=useState("");

  let email, messageOptional;
  const submit = () =>{
    email &&
  messageOptional &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      MESSAGE: messageOptional.value
    });

if(email &&
  messageOptional &&
    email.value.indexOf("@") > -1 ){
      SetError2("ok");
      SetMessage2(" Email");

    }else{
      SetError2("error");
      SetMessage2("Invalid Email");
    }

  };

  return (
    <div style={{}}
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {error2 === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message2 }}
        />
      )}
   
      <input
        style={{  fontSize: "1.5em", margin: ".25em" ,width:"500px",maxWidth:"95%"  }}
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <br />
         <textarea
        style={{  fontSize: "1.5em", margin: ".25em" ,width:"500px",maxWidth:"95%"  }}
        ref={node => (messageOptional = node)}
        type="text"
        placeholder="Message (optional)"
        rows={4}
      />
      <br />
      <button
        style={{ fontSize: "1.5em", margin: ".25em" }}
      
        onClick={submit}>
        Submit
      </button>
    </div>
  );
};


export const SignupForm = () => {
  return (<div style={{

  }}
  
  
  
  > 
    <h1 style={{ textAlign: "center", marginTop:"0em" }}>Contact</h1>
    <p>Sign up for the the mailing list written by Dr.&nbsp;Clement&nbsp;Shimizu</p>
    <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
    />

  </div>);
};


     